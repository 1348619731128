<template>
  <div class="swiper-container" ref="cur">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(img,index) in skuImageList" :key="img.id">
        <img :src="img.imgUrl" :class="{active:currentIndex===index}" @click="changeCurrentIndex(index)">
      </div>
    </div>
    <div class="swiper-button-next" ></div>
    <div class="swiper-button-prev"></div>
  </div>
</template>

<script>

import Swiper from "swiper";

export default {
  name: "ImageList",
  data() {
    return {
      currentIndex: 0
    };
  },
  props: ["skuImageList"],
  methods: {
    changeCurrentIndex(index) {
      this.currentIndex = index;
      // 通知父组件修改当前的索引值
      this.$bus.$emit("changeCurrentIndex", index);
    }
  },
  watch: {
    skuImageList() {
      //保证数据发生修改,页面结构再次渲染完毕。在初始化Swiper实例
      this.$nextTick(() => {
        //初始化Swiper类的实例
        new Swiper(this.$refs.cur, {
          //设置轮播图防线
          direction: "horizontal",
          loop: true,
          // 如果需要前进后退按钮
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          // 展示区域同时展示三张图片
          slidesPerView: 3
        });
      });
    }
  }
};
</script>

<style lang="less" scoped>
.swiper-container {
  height: 56px;
  width: 412px;
  box-sizing: border-box;
  padding: 0 12px;

  .swiper-slide {
    width: 56px;
    height: 56px;

    img {
      width: 100%;
      height: 100%;
      border: 1px solid #ccc;
      padding: 2px;
      width: 50px;
      height: 50px;
      display: block;

      &.active {
        border: 2px solid #f60;
        padding: 1px;
      }

      &:hover {
        border: 2px solid #ffb47f;
        padding: 1px;
      }
    }
  }

  .swiper-button-next {
    left: auto;
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
    right: auto;
  }

  .swiper-button-next,
  .swiper-button-prev {
    box-sizing: border-box;
    width: 12px;
    height: 56px;
    background: rgb(235, 235, 235);
    border: 1px solid rgb(204, 204, 204);
    top: 0;
    margin-top: 0;

    &::after {
      font-size: 12px;
    }
  }
}
</style>

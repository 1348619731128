import { reqCategoryList, reqBannerList, reqFloorList } from '@/api';

const state = {
  // 保存分类列表数据
  categoryList: [],
  // 保存首页轮播图数据
  bannerList: [],
  // 保存 floor 数据
  floorList: [],
};
const mutations = {
  // 直接修改分类列表数据
  RECEIVE_CATEGORY_LIST(state, categoryList) {
    state.categoryList = categoryList;
  },
  // 直接修改首页轮播图数据
  RECEIVE_Banner_LIST(state, bannerList) {
    state.bannerList = bannerList;
  },
  // 直接修改 floor 数据
  RECEIVE_Floor_LIST(state, floorList) {
    state.floorList = floorList;
  },
};
const actions = {
  // 发送请求,获取分类列表数据
  getCategoryList({ commit }) {
    reqCategoryList().then((res) => {
      if (res.code === 200) {
        commit('RECEIVE_CATEGORY_LIST', res.data);
      }
    });
  },
  // 获取首页轮播图
  async getBannerList({ commit }) {
    let res = await reqBannerList();
    if (res.code === 200) {
      commit('RECEIVE_Banner_LIST', res.data);
    }
  },
  // 获取 floor 数据
  async getFloorList({ commit }) {
    let res = await reqFloorList();
    if (res.code === 200) {
      commit('RECEIVE_Floor_LIST', res.data);
    }
  },
};
const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};

<template>
    <div>Register</div>
</template>

<script>
export default {
    name: 'RegisterPage',
    data() {
        return {}
    },
}
</script>

<style  lang="less" scoped></style>
import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import TypeNav from '@/components/TypeNav';
import Carousel from '@/components/Carousel';
import Pagination from '@/components/Pagination';
// Mock
import '@/mock/mockServe.js';
// swiper 样式
import 'swiper/css/swiper.css';
// 全局注册组件
Vue.component(TypeNav.name, TypeNav);
Vue.component(Carousel.name, Carousel);
Vue.component(Pagination.name, Pagination);
Vue.config.productionTip = false;

// 测试接口
// import { reqCategoryList } from '@/api';
// reqCategoryList().then((r) => console.log(r));

new Vue({
  render: (h) => h(App),
  // 全局事件总线
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
  router, // 注册路由器
  store, // 注册仓库
}).$mount('#app');

<template>
  <div id="app">
    <Header></Header>
    <!-- <img alt="Vue logo" src="./assets/logo.png">-->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <router-view></router-view>
    <!-- 在 Home 和 Search 下显示, Login 和 Register 下隐藏 -->
    <!-- <Footer v-show="$route.path == '/home' || $route.path == '/search'"></Footer> -->
    <!-- 使用 meta 实现动态显示 Footer -->
    <Footer v-show="!$route.meta.isHideFooter" />
  </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  mounted() {
    console.log('App mounted');
    // 派发 action 获取商品分类列表，App 组件只会执行一次 
    this.$store.dispatch('getCategoryList');
  },
};
</script>

<style></style>

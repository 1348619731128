import Home from '@/pages/Home';
import Search from '@/pages/Search';
import Register from '@/pages/Register';
import Login from '@/pages/Login';
import Detail from '@/pages/Detail';

/*
所有静态路由配置的数组
*/
export default [
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/detail/:skuId',
    component: Detail,
  },
  {
    path: '/store-test',
    component: () => import('@/pages/StoreTest'),
  },
  {
    // ? 表示 keyword 可有可无
    path: '/search/:keyword?',
    meta: { show: true },
    component: Search,
    name: 'search',
    // props: true 表示将路由路径参数映射为组件的 props 数据
    // 布尔值写法 只能传递 params
    // props: true
    // 对象写法
    // props: {a: 1, b: 2, c: 3}
    // 函数写法
    props: (route) => ({ k: route.params.keyword }),
  },
  {
    path: '/register',
    component: Register,
    meta: {
      // 需要隐藏 footer 的路由添加此配置
      isHideFooter: true,
    },
  },

  {
    path: '/login',
    component: Login,
    meta: {
      isHideFooter: true,
    },
  },
  // 重定向
  {
    path: '/',
    redirect: '/home',
  },
];

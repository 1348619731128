import { reqSearchList } from '@/api';

const state = {
  searchList: {},
};
const mutations = {
  GET_SEARCH_LIST(state, searchList) {
    state.searchList = searchList;
  },
};
const actions = {
  async getSearchList({ commit }, params = {}) {
    const res = await reqSearchList(params);
    if (res.code === 200) {
      commit('GET_SEARCH_LIST', res.data);
    }
  },
};
// 简化仓库数据
const getters = {
  goodsList(state) {
    return state.searchList.goodsList || [];
  },
  trademarkList(state) {
    return state.searchList.trademarkList || [];
  },
  attrsList(state) {
    return state.searchList.attrsList || [];
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

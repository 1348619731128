<template>
  <!-- 商品分类导航 -->
  <div class="type-nav">
    <div class="container">
      <div @mouseleave="leaveShow" @mouseenter="enterShow">
        <h2 class="all">全部商品分类</h2>
        <!-- 过渡动画 -->
        <transition name="sort">
          <!-- 三级联动 -->
          <div class="sort" v-show="show">
            <div class="all-sort-list2" @click="goSearch">
              <div class="item" v-for="(c1, index) in categoryList.slice(0, 16)" :key="c1.categoryId"
                :class="{ cur: currentIndex === index }">
                <h3 @mouseenter="changeIndexT(index)">
                  <a :data-categoryName="c1.categoryName" :data-category1Id="c1.categoryId">
                    {{ c1.categoryName }}
                  </a>
                </h3>
                <div class="item-list clearfix" :style="{
                  display: currentIndex === index ? 'block' : 'none',
                }">
                  <div class="sub-item" v-for="c2 in c1.categoryChild" :key="c2.categoryId">
                    <dl class="fore">
                      <dt>
                        <a :data-categoryName="c2.categoryName" :data-category2Id="c2.categoryId">
                          {{ c2.categoryName }}
                        </a>
                      </dt>
                      <dd>
                        <em v-for="c3 in c2.categoryChild" :key="c3.categoryId">
                          <a :data-categoryName="c3.categoryName" :data-category3Id="c3.categoryId">
                            {{ c3.categoryName }}
                          </a>
                        </em>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <nav class="nav">
        <a href="###">服装城</a>
        <a href="###">美妆馆</a>
        <a href="###">尚品汇超市</a>
        <a href="###">全球购</a>
        <a href="###">闪购</a>
        <a href="###">团购</a>
        <a href="###">有趣</a>
        <a href="###">秒杀</a>
      </nav>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import throttle from 'lodash/throttle';

export default {
  name: 'TypeNav',
  data() {
    return {
      // 当前选中的分类索引
      currentIndex: -1,
      show: true,
    };
  },
  mounted() {
    console.log('TypeNav mounted');
    // 组件挂载完毕后
    // 判断路由是否在 /search 路径下
    // 如果是，就不显示商品分类导航
    if (this.$route.path != '/home') {
      this.show = false;
    }
  },
  methods: {
    // 修改 currentIndex
    changeIndex(index) {
      // 如果用户操作过快，回调函数内业务多，会导致卡顿，需要防抖和节流
      // 防抖：用户操作过快，只执行最后一次
      // 节流：用户操作过快，只执行第一次
      this.currentIndex = index;
    },
    // 节流
    changeIndexT: throttle(function (index) {
      this.currentIndex = index;
    }, 50),
    // 利用事件委派,跳转到搜索页面
    // 需要解决的问题,如何确定获取点击的分类的a标签和参数
    goSearch(event) {
      // 获取触发事件的节点
      // console.log(event.target);
      let { categoryname, category1id, category2id, category3id } =
        event.target.dataset;
      // 标签有 categoryname 属性说明是分类的a标签
      if (categoryname) {
        //点击只要是a,就是往search模块跳转
        let locations = {
          name: 'search',
          query: { categoryName: categoryname },
        };
        //一级分类的a
        if (category1id) {
          locations.query.category1Id = category1id;
        } else if (category2id) {
          //二级分类的a
          locations.query.category2Id = category2id;
        } else {
          //三级分类的a
          locations.query.category3Id = category3id;
        }
        //点击商品分类按钮的时候,如果路径当中携带params参数,需要合并携带给search模块
        if (this.$route.params.keyword) {
          locations.params = this.$route.params;
        }
        //目前商品分类这里携带参数只有query参数
        this.$router.push(locations);
      }
    },
    // 鼠标进入显示商品分类导航
    enterShow() {
      this.show = true;
    },
    // 鼠标离开隐藏商品分类导航
    leaveShow() {
      // 只在 search 模块下，鼠标离开，隐藏商品分类导航
      if (this.$route.path != '/home') {
        this.show = false;
      }
      this.currentIndex = -1;
    },
  },
  computed: {
    ...mapState({
      categoryList: (state) => state.home.categoryList,
    }),
  },
};
</script>
<style scoped lang="less">
.type-nav {
  border-bottom: 2px solid #e1251b;

  .container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    position: relative;

    .all {
      width: 210px;
      height: 45px;
      background-color: #e1251b;
      line-height: 45px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
    }

    .nav {
      a {
        height: 45px;
        margin: 0 22px;
        line-height: 45px;
        font-size: 16px;
        color: #333;
      }
    }

    .sort {
      position: absolute;
      left: 0;
      top: 45px;
      width: 210px;
      height: 461px;
      background: #fafafa;
      z-index: 999;

      .all-sort-list2 {
        .cur {
          background: #548dce;
        }

        .item {
          h3 {
            line-height: 30px;
            font-size: 14px;
            font-weight: 400;
            overflow: hidden;
            padding: 0 20px;
            margin: 0;

            //&:hover {
            //  background: #548dce;
            //  a{
            //    color: white;
            //  }
            //}

            a {
              color: #333;
            }
          }

          .item-list {
            display: none;
            position: absolute;
            width: 734px;
            min-height: 460px;
            background: #f7f7f7;
            left: 210px;
            border: 1px solid #ddd;
            top: 0;
            z-index: 9999 !important;

            .sub-item {
              float: left;
              width: 650px;
              padding: 0 4px 0 8px;

              dl {
                border-top: 1px solid #eee;
                padding: 6px 0;
                overflow: hidden;
                zoom: 1;

                &.fore {
                  border-top: 0;
                }

                dt {
                  float: left;
                  width: 54px;
                  line-height: 22px;
                  text-align: right;
                  padding: 3px 6px 0 0;
                  font-weight: 700;
                }

                dd {
                  float: left;
                  width: 415px;
                  padding: 3px 0 0;
                  overflow: hidden;

                  em {
                    float: left;
                    height: 14px;
                    line-height: 14px;
                    padding: 0 8px;
                    margin-top: 5px;
                    border-left: 1px solid #ccc;
                  }
                }
              }
            }
          }

          //&:hover {
          //  .item-list {
          //    display: block;
          //  }
          //}
        }
      }
    }

    // 过渡动画样式
    .sort-enter {
      height: 0px;
    }

    .sort-enter-active {
      transition: all 0.3s;
    }

    .sort-enter-to {
      height: 461px;
    }
  }
}
</style>

// 引入 mockjs 插件开始模拟数据
import Mock from 'mockjs';
// 引入数据：JSON 数据格式数据
// 比如:图片资源、JSON 资源【里面不能书写 export 关键字】，这些资源默认对外暴露【默认暴露】
import banner from './banner.json';
import floor from './floor.json';

// 接口:相当于 nodejs 里面中间件
// 第一个参数：接口的地址 第二个参数:向这个接口发请求获取到的数据
// Mock 插件：中间件默认是 GET 请求
Mock.mock('/mock/banner', { code: 200, data: banner });
// Mock 插件：中间件默认是 GET 请求
Mock.mock('/mock/floor', { code: 200, data: floor });
// 对于axios进行二次封装
import axios from 'axios';
//引入进度条
import nprogress from 'nprogress';
//引入相关进度条的样式
import 'nprogress/nprogress.css';

// axios.create 方法执行,其实返回一个 axios 和 request 一样的
let requests = axios.create({
  // 基础路径,发请求 URL 携带 api【真实服务器接口都携带 /api】
  baseURL: '/api',
  // 超时的设置
  timeout: 10000,
});
// 请求拦截器:将来项目中【N个请求】，只要发请求,会触发请求拦截器!!!
requests.interceptors.request.use((config) => {
  //进度条开始
  nprogress.start();
  return config;
});

// 响应拦截器：请求数据返回会执行
requests.interceptors.response.use(
  (res) => {
    nprogress.done();
    // res:实质就是项目中发请求、服务器返回的数据
    return res.data;
  },
  (err) => {
    // 温馨提示:某一天发请求,请求失败,请求失败的信息打印出来
    alert(err.message);
    // 终止Promise链
    return new Promise();
  },
);

// 最后需要暴露:暴露的是添加新的功能的axios,即为requests
export default requests;

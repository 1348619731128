import Vue from 'vue';
import Vuex from 'vuex';
import home from './home';
import search from './search';
import detail from '@/store/detail';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    home,
    search,
    detail,
  },
  state: { storeTestCount: 5 },
  mutations: {
    INC(state) {
      state.storeTestCount += 1;
    },
    DEC(state) {
      state.storeTestCount -= 1;
    },
  },
  actions: {
    incrementStoreTestCount({ commit }) {
      commit('INC');
    },
    decrementStoreTestCount({ commit }) {
      commit('DEC');
    },
  },
  getters: {},
});
